import store from "@/store";
import router from "@/routes";

const payloadData = { data: null, profileUrl: null };
export const logoutUser = () => {
  store.dispatch("userManagement/logOutUser");
  store.dispatch("clientDashboard/updateProject", {});
  store.dispatch("devOnboarding/setLinkedinProfile", payloadData);
  localStorage.removeItem("engineerRole");
  localStorage.removeItem("vcs");

  //router.push({ name: "login" });
  //alert(`${this.$Website}`);
  // alert(app.config.globalProperties);

  document.location.href = process.env.VUE_APP_SPLASHPAGE;
};

export const deleteAccount = () => {
  store.dispatch("userManagement/logOutUser");
  store.dispatch("clientDashboard/updateProject", {});
  store.dispatch("devOnboarding/setLinkedinProfile", payloadData);
  router.push({ name: "user_type" });
};
