<template>
  <v-toolbar
    class="mb-0 navbar-wrapper"
    height="80"
    elevation-0
    style="background-color: white"
  >
    <v-toolbar-title>
      <span v-bind:style="{ 'margin-left': screenSize.logoMargin }"></span>
      <!-- <span
        class="project-name"
        :style="clientProject.project_name ? 'color: #1B1E22;' : null"
        >{{
          clientProject.project_name
            ? clientProject.project_name
            : "Product Name_"
        }}</span
      > -->
      <img
        src="/img/profile/grupa-logo.png"
        width="120"
        style="cursor: pointer"
        @click="handleHome"
      />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <span class="text-lg-right">{{ shortenName(currentUser.name) }}</span>
    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
      v-bind:style="{ 'margin-right': screenSize.margin_right }"
    >
      <v-btn flat slot="activator" icon>
        <v-icon>keyboard_arrow_down</v-icon>
      </v-btn>

      <v-list>
        <!-- <v-list-tile>
          <v-list-tile-title style="cursor:pointer" @click="goHome">Home</v-list-tile-title>
        </v-list-tile>-->
        <v-list-tile>
          <v-list-tile-title style="cursor: pointer" @click="handleDashboard"
            >Dashboard</v-list-tile-title
          >
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-title style="cursor: pointer" @click="handleLogout"
            >Logout</v-list-tile-title
          >
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-toolbar-items class="hidden-sm-and-down"></v-toolbar-items>
  </v-toolbar>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";
import shortName from "@/mixins/shortName";
import { logoutUser } from "@/utils/logoutUser";

export default {
  mixins: [shortName],
  data: () => ({
    name: null,
    items: [
      { name: "Home", url: "./" },
      { name: "About us", url: "./about-us" },
      { name: "home", url: "./logout" },
    ],
  }),
  mounted() {},
  methods: {
    handleHome() {
      // window.location.href = `${process.env.VUE_APP_SPLASHPAGE}`;
    },
    handleDashboard() {
      if (this.currentUser.user_type == 1) {
        this.$router.push({ name: "dev_dashboard" });
      } else if (this.currentUser.user_type == 2) {
        let projectId = this.$route.params.id;
        if (projectId) {
          this.$router.push({
            name: "project_workspace",
            params: { projectId },
          });
        }
      }
    },
    handleLogout() {
      logoutUser();
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", ["clientProject"]),

    screenSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return { logoMargin: "0px", margin_right: "0px" };
        case "sm":
          return { logoMargin: "0px", margin_right: "0px" };
        case "md":
          return { logoMargin: "50px", margin_right: "50px" };
        case "lg":
          return { logoMargin: "100px", margin_right: "100px" };
        case "xl":
          return { logoMargin: "100px", margin_right: "100px" };
      }
    },
  },
};
</script>
<style scoped>
.navbar-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  /* position: fixed; */
}
.project-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #979da5;
}
</style>
